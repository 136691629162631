import React from "react";
import styled from "styled-components";
import { Tabs } from "../../../../components"
import { COLOR } from "../../../../components/theme";


const StyledTab = styled.div`
  background-color: ${COLOR.WHITE};
`;

 const StatementTabs = () => {
    return (
        <StyledTab>
            <Tabs
                settlementTabs
                items={[
                    {
                        id: "daily-settlements",
                        title: "Daily settlement summary",
                        redirectUrl: "/merchant/statements/settlementsummary"
                    },
                    {
                        id: "graphs",
                        title: "Charts",
                        redirectUrl: "/merchant/statements/graphs"
                    },
                ]}
            />
        </StyledTab>
    )
}


export default StatementTabs;