import React from "react";
import styled from "styled-components";
import {
  NavigationalLayout,
  StatementHeading,
} from "../../../layout/merchant";
import Scene from "../../../layout/merchant/settlements/Scene";
import { BREAKPOINT, COLOR } from "../../../components/theme";
import SettlementTabs from "../../../layout/merchant/settlements/components/StatementTabs";
import Graphs from "../../../layout/merchant/settlements/components/Graphs";



const StyledContainer = styled.div`
  height: 160vh;
  padding: 20px 15px;
  background-color: ${COLOR.WHITE};

  @media (min-width: ${BREAKPOINT.SIZE_TABLET}) {
    padding: 20px 30px;
  }
`;
export default () => {


  return (
      <NavigationalLayout>
        <StatementHeading />
        <SettlementTabs />
        <StyledContainer>
          <Scene.FullWidth>
            <Graphs />
          </Scene.FullWidth>
        </StyledContainer>
      </NavigationalLayout>
  );
};
