import React from "react";
import styled from "styled-components";
import { Button, Grid, Icon } from "basis";
import { LoadingMessage, Text } from "../../../components";
import { BREAKPOINT, COLOR } from "../../../components/theme";
import { StatementHeading, NavigationalLayout } from "../components";
import  StatementTabs  from './components/StatementTabs'

const StyledContainer = styled.div`
  padding: 20px 15px;
  background-color: ${COLOR.WHITE};

  @media (min-width: ${BREAKPOINT.SIZE_TABLET}) {
    padding: 20px 30px;
  }
`;

const Scene = ({ children, loading = false, exportData, dashboardTitle, statementsHeading, statementsTabs=false}) => {
  if (loading) {
    return (
      <NavigationalLayout>
        <LoadingMessage message="Please wait while we fetch your transactions.." />
      </NavigationalLayout>
    );
  }

  return (
    <NavigationalLayout>
      <StatementHeading statementsHeading={statementsHeading}/>
      {statementsTabs&&(<StatementTabs/>)}
      <StyledContainer>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between"}} >
          <Text textStyle="heading6">{dashboardTitle}</Text>
          <Button
            showButton
            onClick={() => { exportData() }}
          >
            <div style={{ display: "flex", alignItems: "center" }} >
              <Icon name="download" color="white" />
              Export Data
            </div>
          </Button>
        </div>
        <Grid preset="page" rowsGap={0}>

          {children}
        </Grid>
      </StyledContainer>
    </NavigationalLayout>
  );
};

const FullWidth = ({ children }) => (
  <Grid.Item colSpan="0-12">{children}</Grid.Item>
);

Scene.FullWidth = FullWidth;

export default Scene;
