import React, { useEffect, useState, useRef } from "react";
import { Grid } from "basis";
import styled from "styled-components";
import { Container, Text, Button, Icon } from "basis";
import { Select } from '../../../../components'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import { ErrorMessage, Loading } from "../../../../components";
import * as api from '../../utils/api'
import { summaryDataModel, processInput } from '../utils/summaryDataModel';
import { options, yearsArray, monthNames } from '../constants'
import { BREAKPOINT } from "../../../../components/theme";


const Box = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: ${BREAKPOINT.SIZE_MOBILE}) {
    flex-direction: column;
  }
 
`;
const DisplaySearch = styled.div`
@media screen and (max-width: ${BREAKPOINT.SIZE_MOBILE}) {
   display:none
  }

`
const HeadingSpacerTop = styled.div`
margin-top: 30px
`;
const HeadingSpacer = styled.div`
margin-bottom: 30px
`;
const Spacer = styled.div`
margin-bottom: 10px
`;
const SpacerRight = styled.div`
margin-right 20px
`;
const LeftElement = styled.div`
  display: flex
`;

const AlignFilter = styled.div`
  margin-top: 32px;
`;
const RightElement = styled.div`
  display: flex;
  `
const RightElementSecondRow = styled.div`
  display: flex;
}
`;

const Graphs = () => {
  const currentDate = new Date()
  const currentMonth = currentDate.getMonth() + 1;
  const [dailyGraphs, setdailyGraphs] = useState([]);
  const [monthlyGraphs, setMonthlyGraphs] = useState([]);
  const [dailyTotal, setDailyTotal] = useState();
  const [monthlyTotal, setMonthlyTotal] = useState()
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true)
  const [month, setMonth] = useState(currentMonth);
  const [year, setYear] = useState(currentDate.getFullYear());
  const [yearly, setYearly] = useState(currentDate.getFullYear())
  const [labelMonth, labelSetMonth] = useState(currentDate.toLocaleString('en-US', { month: 'long' }));
  const [labelYear, labelSetYear] = useState(currentDate.getFullYear());
  const [labelYearly, labelSetYearly] = useState(currentDate.getFullYear())
  const [monthName, setMonthName] = useState(currentDate.toLocaleString('en-US', { month: 'long' }))

  const initGraphs = async (year, month) => {
    const { response, success } = await api.dailyStatements({
      fromDate: `${year}-01-01`,
      toDate: `${year}-12-31`,
      // maxRows: 25,
      exportType: 'GRAPH'

    });
    setLoading(true)
    if (success) {
      setLoading(false)
      setError(false)
      const { dailyResult, dailyAccumulated } = processInput(response?.data?.statementData, year, month)
      const { monthlyResult, monthlyAccumulated } = summaryDataModel(response?.data?.statementData);
      return { monthlyResult, dailyResult, dailyAccumulated, monthlyAccumulated }
    } else {
      setError(response.message)
    }
  }

  const elementToMeasureRef = useRef();
  const elementToAdjustRef = useRef();
  function getMonthName(monthNumber) {
    return monthNames[monthNumber - 1]
  }
  useEffect(() => {
    const elementToMeasure = elementToMeasureRef?.current;
    const elementToAdjust = elementToAdjustRef?.current;

    if (elementToMeasure && elementToAdjust) {
      const monthValue = getMonthName(month)
      let desiredMargin;
      if (monthValue  === 'September' || monthValue  === 'November' || monthValue  === 'December') {
        desiredMargin = 70;
      } else if (monthValue  === 'January' || monthValue  === 'February' || monthValue  === 'August' || monthValue  === 'October') {
        desiredMargin =  60;
      } else if (monthValue  === 'May' || monthValue  === 'June' || monthValue  === 'July') {
        desiredMargin = 30;
      }
      else {
        desiredMargin = 35;
      }
      elementToAdjust.style.marginRight = `${desiredMargin}px`;
    }
  }, [elementToMeasureRef, elementToAdjustRef, month, dailyGraphs, monthlyGraphs]);
  useEffect(() => {
    async function fetchData() {
      const currentDate = new Date();
      const year = currentDate?.getFullYear();
      const month = currentDate?.getMonth() + 1;
      const { dailyResult, monthlyResult, dailyAccumulated, monthlyAccumulated } = await initGraphs(year, month)
      setdailyGraphs(dailyResult);
      setMonthlyGraphs(monthlyResult)
      setDailyTotal(dailyAccumulated);
      setMonthlyTotal(monthlyAccumulated)
      const elementToMeasure = elementToMeasureRef?.current;
      const elementToAdjust = elementToAdjustRef?.current;
  
      if (elementToMeasure && elementToAdjust) {
        const monthValue = getMonthName(month)
        let desiredMargin;
        if (monthValue  === 'September' || monthValue  === 'November' || monthValue  === 'December') {
          desiredMargin = 70;
        } else if (monthValue  === 'January' || monthValue  === 'February' || monthValue  === 'August' || monthValue  === 'October') {
          desiredMargin =  60;
        } else if (monthValue  === 'May' || monthValue  === 'June' || monthValue  === 'July') {
          desiredMargin = 30;
        }
        else {
          desiredMargin = 35;
        }
        elementToAdjust.style.marginRight = `${desiredMargin}px`;
      }
    }
     
    fetchData()
  }, [])
  
  const handleMonthChange = (event) => {
    const monthValue = getMonthName(event.target.value)
    setMonthName(monthValue)
    setMonth(event.target.value);
  };

  const handleYearChange = (event) => {
    setYear(event.target.value);

  };
  const handleYearlyChange = (event) => {
    setYearly(event.target.value);

  };

  const handleDateSelection = () => {
    if (year != '' && month != '') {
      async function fetchData() {
        const { dailyResult, dailyAccumulated } = await initGraphs(year, month)
        setdailyGraphs(dailyResult);
        setDailyTotal(dailyAccumulated);
        const monthValue = getMonthName(month)
        labelSetMonth(monthValue)
        labelSetYear(year)
      }

      fetchData()
    }
  };
  const handleYearlyFilterChange = (event) => {
    async function fetchData() {

      const { monthlyResult, monthlyAccumulated } = await initGraphs(yearly, month)
      setMonthlyGraphs(monthlyResult)
      setMonthlyTotal(monthlyAccumulated)
      labelSetYearly(yearly)
    }
    fetchData()

  };

  if (error) {
    return (
      <Grid.Item colSpan="all">
        <ErrorMessage description="Unable to display graphs" />
      </Grid.Item>
    );
  }
  if (loading) {
    return (
      <Loading />
    )
  }
  function formatNumber(number = 0) {
    const parts = number?.toString()?.split('.');
    const integerPart = parts[0];
    const decimalPart = parts[1] || '';
  
    const formattedInteger = integerPart?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  
    return formattedInteger + (decimalPart ? '.' + decimalPart : '');
  }

  return (


    <>
      <Grid preset="page" >
        <Grid.Item colSpan-lg="all" colSpan-sm="all" colSpan="all">
          <Container bg="white" padding="4" height="240" >
            <Container margin="0 0 4 2">
              <Text as="h5" textStyle="heading5">Daily Settlements Chart</Text>
              <HeadingSpacer />
              <Box>
                <LeftElement>
                  <div>
                    <Text color="grey.t75">Month</Text>
                    <Spacer />
                    <Select
                      grey
                      name="Month"
                      color="silver"
                      selected={month}
                      options={options}
                      onChange={handleMonthChange}
                    />
                  </div>

                  <SpacerRight />
                  <div>
                    <Text color="grey.t75">Year</Text>
                    <Spacer />
                    <Select
                      grey
                      name="Year"
                      color="silver"
                      selected={year}
                      options={yearsArray}
                      onChange={handleYearChange}
                    />
                  </div>


                  <Spacer />
                  <SpacerRight />
                  <AlignFilter>
                    <Button variant="secondary" onClick={handleDateSelection}><Icon name="search" color="primary.blue.t100" /></Button>
                  </AlignFilter>
                </LeftElement>


                <RightElement>
                  <div ref={elementToMeasureRef}>
                    <Text color="grey.t75">Settlements in {labelMonth} {labelYear}</Text>
                    <Spacer />
                    <Text align='left' as="h2" textStyle="heading3">${formatNumber(dailyTotal?.toFixed(2))}</Text>
                  </div>
                </RightElement>
              </Box>
              <Spacer />
              <DisplaySearch >
              <Text>Select a date and search</Text>
              </DisplaySearch>
              <Spacer />
              <Text as="p" align="center" textStyle="heading6">Chart for {labelMonth} {labelYear}</Text>
            </Container>
            <ResponsiveContainer width={'100%'} height={'90%'}>
              <BarChart data={dailyGraphs}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="day" tick={{ fontSize: 12 }} />
                <YAxis width={40} tick={{ fontSize: 12 }}>
                </YAxis>
                <Tooltip />
                <Legend />
                <Bar dataKey="GEM" fill="#336BBB" name="GEM" />
                <Bar dataKey="GOMC" fill="#7669CD" name="GOMC" />

              </BarChart>

            </ResponsiveContainer>
            <HeadingSpacerTop />
            <Container margin="0 0 4 2" >

              <Text as="h5" textStyle="heading5">Monthly Settlements Chart</Text>
              <HeadingSpacer />
              <Box>
                <LeftElement>

                  <div>
                    <Text color="grey.t75" >Year</Text>
                    <Spacer />
                    <Select
                      grey
                      name="Year"
                      color="silver"
                      selected={yearly}
                      options={yearsArray}
                      onChange={handleYearlyChange}
                    />
                    
                  </div>


                  <Spacer />
                  <SpacerRight />
                  <AlignFilter>
                    <Button variant="secondary" onClick={handleYearlyFilterChange}><Icon name="search" color="primary.blue.t100" /></Button>
                
                  </AlignFilter>
                </LeftElement>


                <RightElementSecondRow>
                  <div ref={elementToAdjustRef}>
                    <Text color="grey.t75">Settlements in  {labelYearly}</Text>
                    <Spacer />
                    <Text as="h2" textStyle="heading3">${formatNumber(monthlyTotal?.toFixed(2))}</Text>
                  </div>
                </RightElementSecondRow>
              </Box>
              <Spacer />
              <DisplaySearch >
              <Text>Select a date and search</Text>
              </DisplaySearch>
              <Spacer />
              <Text as="p" align="center" textStyle="heading6">Chart for {labelYearly}</Text>
            </Container>
            <ResponsiveContainer width={'100%'} height={'90%'}>
              <BarChart data={monthlyGraphs}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="month" tick={{ fontSize: 12 }} />
                <YAxis width={40} tick={{ fontSize: 12 }}>
                </YAxis>
                <Tooltip />
                <Legend />
                <Bar dataKey="GEM" fill="#336BBB" name="GEM" />
                <Bar dataKey="GOMC" fill="#7669CD" name="GOMC" />
              </BarChart>
            </ResponsiveContainer>
          </Container >

        </Grid.Item>

      </Grid>

    </>



  )
};

export default Graphs;