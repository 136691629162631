import { monthNames } from '../constants'
export const summaryDataModel = (apiData, year, month) => {

 
  const monthlyUnFormattedResult = (apiData) => {
  
    function calculateTotals(data) {
      const totals = {};
      const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
      ];
    
      // Calculate totals for each year-month combination
      data.forEach(item => {
        const { cardType, date, settlementAmount } = item;
        const [year, monthIndex] = date.split('-');
        const monthName = monthNames[parseInt(monthIndex) - 1];
        const key = `${year}-${monthName}`;
    
        if (!totals[key]) {
          totals[key] = {
            month : monthName,
            year,
            GEM: 0,
            GOMC: 0
          };
        }
    
        totals[key][cardType] += parseFloat(settlementAmount);
      });
    
  // Ensure all months from the data year are included in the output
  const dataYear = data[0]?.date?.split('-')[0];
      const output = [];
      monthNames.forEach(month => {
        const yearMonth = `${dataYear}-${month}`;
        if (!totals[yearMonth]) {
          totals[yearMonth] = {
            month,
            year: dataYear,
            GEM: 0,
            GOMC: 0
          };
        }
        output.push(totals[yearMonth]);
      });
    
      return output;
    }
    
    // Call the function and get the results
    const result = calculateTotals(apiData);
    const reversedArray = [...result].slice().reverse();

    const monthlyResult = Object.entries(reversedArray.reverse()).map(([yearMonth, data]) => ({
      yearMonth,
      ...data,
    }));
   
    return monthlyResult;
  };
  const monthlyUnFormatted = monthlyUnFormattedResult(apiData)
  const monthlyDecimalResult = Object.entries(monthlyUnFormatted).map(([yearMonth, data]) => ({
    yearMonth,
    ...data,
  }));
  const  monthlyResult = monthlyDecimalResult.map(item => ({
    ...item,
    GEM :formatMonthly(item?.GEM),
    GOMC:  formatMonthly(item?.GOMC)
  }));

function formatMonthly(current) {
  const formatted = current?.toFixed(2);
  return parseFloat(formatted);
}
 
  let monthlyAccumulated = monthlyResult.reduce((accumulator, current) => {
    let consolidated = current.GEM + current.GOMC
    return accumulator + consolidated
  }, 0);
  return { monthlyResult, monthlyAccumulated }
}


export function processInput(input, year, month) {
  const targetYear = parseInt(year);
  const targetMonth = parseInt(month);

  // Filter data for the target year and month
  const filteredData = input.filter(item => {
    const itemYear = parseInt(item.date.substring(0, 4));
    const itemMonth = parseInt(item.date.substring(5, 7));

    return itemYear === targetYear && itemMonth === targetMonth;
  });

  // Create a complete list of days in the target month
  const daysInMonth = new Date(targetYear, targetMonth, 0).getDate();
  const allDays = Array.from({ length: daysInMonth }, (_, i) => i + 1);

  // Group data by date and calculate totals
  const groupedData = filteredData.reduce((acc, item) => {
    const dateKey = item.date;

    if (!acc[dateKey]) {
      acc[dateKey] = {
        day: item.date.substring(8, 10),
        month: item.date.substring(5, 7),
        GEM: 0,
        GOMC: 0,
      };
    }

    if (item.cardType === "GEM") {
      acc[dateKey].GEM += parseFloat(item.settlementAmount);
    } else if (item.cardType === "GOMC") {
      acc[dateKey].GOMC += parseFloat(item.settlementAmount);
    }

    return acc;
  }, {});

  // Merge grouped data with all days to include missing days
  const dailyResult = allDays.map(day => {
    const dateKey = `${targetYear}-${targetMonth.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
    return groupedData[dateKey] || {
      day: day.toString().padStart(2, '0'),
      month: targetMonth.toString().padStart(2, '0'),
      GEM: 0,
      GOMC: 0,
    };
  });
  let dailyAccumulated = dailyResult.reduce((accumulator, current) => {
    let consolidated = current.GEM + current.GOMC
    return accumulator + consolidated
  }, 0);
  return {dailyResult, dailyAccumulated}
}